const dev = {
    // ANALYTICS_URL:'http://localhost:8080'
    ANALYTICS_URL:'https://arotx5y779.execute-api.ap-southeast-2.amazonaws.com/default/googleAnalytics'
};
const prod = {
    // analyticsUrl:'https://us-central1-qualified-day-323102.cloudfunctions.net/GoogleAnalyticsAPI',
    ANALYTICS_URL:'https://arotx5y779.execute-api.ap-southeast-2.amazonaws.com/default/googleAnalytics'
};

const config = process.env.NODE_ENV === 'development'
    ? dev: prod;
const configConstants = {
    FLAGS_URL:['https://www.countryflags.io/','/shiny/32.png'],
    WEBSITE_EMAIL:'feedback@argentgames.xyz',
	BITCOIN_DONATION_ADDRESS:'1BcvUk11CskvSyt9E2JwmYSpHUSWDvqG1z',
    ...config
};

export default configConstants;
