import React, { useState, useEffect, useCallback }  from 'react';
import * as configConstants from './config.js';
const {ANALYTICS_URL, FLAGS_URL} = configConstants.default;

export default function UsersTable(){
    const [countries,setCountries] = useState([]);
    const fetchApi = useCallback(()=>{
    	(async () => {
	        let fetch_url = ANALYTICS_URL;
	        try{
	            let res = await fetch(fetch_url);
	            res = await res.json();
	            setCountries(res);
	        }catch{
	            console.error('Failed to fetch country data');
	        }
        })();
    },[]);
    useEffect(()=>{
    	fetchApi() // eslint-disable-next-line
    },[]);
    return(
        <table className='pure-table pure-table-bordered' style={{position:'static',margin:'auto'}}>
            <caption>Past week's users</caption>
            <thead>
                <tr>
                    <th colSpan="2">Country</th>
                    <th>Users</th>
                </tr>
            </thead>
            <tbody>
            {countries.length === 0 ? <tr><td>Loading...</td></tr> :
                countries.map((country,index)=>(
                    <tr key={index}>
                        <td><img alt={""} src={`${FLAGS_URL[0]}${country.code.toLowerCase()}${FLAGS_URL[1]}`} style={{float:'left'}} /></td>
                        <td>{country.name}</td>
                        <td>{country.users}</td>
                    </tr>
                ))
            }
            </tbody>
        </table>
    );
}
