import './App.css';
import React, {
    // useState,
    // useEffect
}  from 'react';
import UsersTable from './UsersTable';
import * as configConstants from './config.js';

const {WEBSITE_EMAIL, STRIPE_URL} = configConstants.default;
const pageNames = ['pilot','rtsRisk','asteroidMining'];

export default function App() {
    return (
        <div className="App">
            <UsersTable/>
            <p style={{margin:20}}>Email "<a href={`mailto:${WEBSITE_EMAIL}`}>{WEBSITE_EMAIL}</a>" for feedback or questions!</p>
			<h1>Games:</h1>
            {
                pageNames.map((fileName,index) => (
                    <a href={`${fileName}.html`} key={index}>
                        <div>
                            <img src= {`instructionsImages/${fileName}Instructions.png`} className='imageLinks' alt={fileName} />
                        </div>
                    </a>
                ))
            }
        </div>
    );
}
